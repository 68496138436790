import albaranes from '../pages/albaranes/routes.js';
import calendario from '../pages/calendario/routes.js';
import documentacion from '../pages/documentacion/routes.js';
import centrostrabajo from '../pages/centrostrabajo/routes.js';
import clientes from '../pages/clientes/routes.js';
import combos from '../pages/combos/routes.js';
import componentesProteccion from '../pages/componentesproteccion/routes.js';
import comunidades from '../pages/comunidades/routes.js';
import condicionespago from '../pages/condicionespago/routes.js';
import delegaciones from '../pages/delegaciones/routes.js';
import departamentos from '../pages/departamentos/routes.js';
import documentos from '../pages/documentos/routes.js';
import empleados from '../pages/empleados/routes.js';
import empresas from '../pages/empresas/routes.js';
import empresasmantenedoras from '../pages/empresasmantenedoras/routes.js';
import equipos from '../pages/equipos/routes.js';
import evaluadorjs from '../pages/evaluadorjs/routes.js';
import facturas from '../pages/facturas/routes.js';
import formatos from '../pages/formatos/routes.js';
import gruposcliente from '../pages/gruposcliente/routes.js';
import gruposproveedor from '../pages/gruposproveedor/routes.js';
import informes from '../pages/informes/routes.js';
import inspecciones from '../pages/inspecciones/routes.js';
import inventarios from '../pages/inventarios/routes.js';
import items from '../pages/items/routes.js';
import lineasnegocio from '../pages/lineasnegocio/routes.js';
import lineaspedidos from '../pages/lineaspedidos/routes.js';
import listasprecios from '../pages/listasprecios/routes.js';
import materias from '../pages/materias/routes.js';
import municipios from '../pages/municipios/routes.js';
import notificaciones from '../pages/notificaciones/routes.js';
import ofertas from '../pages/ofertas/routes.js';
import paises from '../pages/paises/routes.js';
import pedidos from '../pages/pedidos/routes.js';
import proveedores from '../pages/proveedores/routes.js';
import provincias from '../pages/provincias/routes.js';
import publicRoutes from '../pages/public/routes.js';
import registroemail from '../pages/registroemail/routes.js';
import { routesLeft, routesMain } from '../pages/routes-main.js';
import servicios from '../pages/servicios/routes.js';
import tareas from '../pages/tareas/routes.js';
import campanias from '../pages/campanias/routes.js';
import campaniascomerciales from '../pages/campaniascomerciales/routes.js';
import oportunidades from '../pages/oportunidades/routes.js';
import tiposdocumentos from '../pages/tiposdocumentos/routes.js';
import tiposequipo from '../pages/tiposequipo/routes.js';
import tipositem from '../pages/tipositem/routes.js';
import tipostarea from '../pages/tipostarea/routes.js';
import usuarios from '../pages/usuarios/routes.js';
import viaspago from '../pages/viaspago/routes.js';
import cualificaciones from '../pages/cualificaciones/routes.js';
import personalcualificado from '../pages/personalcualificado/routes.js';
/*import pedidos from '../pages/pedidos/routes.js';
import conflictos from '../pages/conflictos/routes.js';
import marcas from '../pages/marcas/routes.js';
import modelos from '../pages/modelos/routes.js';
import coches from '../pages/coches/routes.js';
import facturas from '../pages/facturas/routes.js';
import config from '../pages/config/routes.js';*/

var routes = [
    ...inspecciones,
    ...clientes,
    ...usuarios,
    ...items,
    ...equipos,
    ...materias,
    ...provincias,
    ...municipios,
    ...paises,
    ...componentesProteccion,
    ...comunidades,
    ...formatos,
    ...gruposcliente,
    ...gruposproveedor,
    ...empresas,
    ...lineasnegocio,
    ...tiposdocumentos,
    ...delegaciones,
    ...centrostrabajo,
    ...departamentos,
    ...empresasmantenedoras,
    ...viaspago,
    ...listasprecios,
    ...servicios,
    ...condicionespago,
    ...pedidos,
    ...lineaspedidos,
    ...ofertas,
    ...proveedores,
    ...empleados,
    ...tiposequipo,
    ...tipositem,
    ...evaluadorjs,
    ...albaranes,
    ...facturas,
    ...notificaciones,
    ...combos,
    ...documentos,
    ...publicRoutes,
    ...calendario,
    ...documentacion,
    ...tipostarea,
    ...tareas,
    ...campanias,
    ...campaniascomerciales,
    ...oportunidades,
    ...inventarios,
    ...informes,
    ...registroemail,
    ...cualificaciones,
    ...personalcualificado,

    /*
    ...facturas,
    ...pedidos,
    ...conflictos,
    ...marcas,
    ...modelos,
    ...coches,
    ...clientes,
    ...config,*/
    ...routesMain
];
export { routes, routesLeft };


import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import Documento from './DocumentoOffline.js';
import FileUtils from '../FileUtils.js';
import DatabaseModelLoki from './DatabaseModelOfflineLoki.js';
import DatabaseModelMongoDB from './DatabaseModelOfflineMongoDB.js';


// Cogemos segun nos interese, la de mongo o la de loki
var DatabaseModel = process.env.DEVICE == 'android' ? Object.create(DatabaseModelMongoDB) : Object.create(DatabaseModelLoki) ;

// Rellenamos los datos de conexión comunes a ambos
DatabaseModel.server = process.env.VUE_APP_SERVER;
DatabaseModel.server_movilidad = process.env.VUE_APP_SERVER_MOVILIDAD;
DatabaseModel.prefix= 'movilidad/';
DatabaseModel.clientId= process.env.VUE_APP_CLIENT_ID;
DatabaseModel.clientSecret= process.env.VUE_APP_CLIENT_SECRET;
DatabaseModel.request= Framework7.request;

DatabaseModel.getCombo = function(params, shownFields = []){
    var self = this;
    var comboParams = Object.assign({}, params);
    // Si vamos por mongo, hacer que en los params se hagan como un like insensitivo
    if (comboParams.filter) {
        for (var f in comboParams.filter) {
            if (f == undefined) {
                continue;
            } else if (f == "withoutAggregations" || f == "onlyAggregations") {
                delete comboParams.filter[f];
                continue;
            }
            comboParams.filter[f] = { "$regex": comboParams.filter[f], "$options": "i" };
        }
    }
    if(shownFields.length){
        comboParams.projection = shownFields.join(",");
    }
    return self.getList(comboParams);
};

DatabaseModel.save = function(params) {
    var self = this;
    params = params || {};
    if (params.itemId) {
        return self.put(params);
    } else {
        return self.post(params);
    }
};

DatabaseModel.uploadDocuments = function(albaranOInspecciones) {
    const self = this;
    var inspecciones = (Array.isArray(albaranOInspecciones) ? albaranOInspecciones : albaranOInspecciones.expedientes) || [];
    let albaran =  (Array.isArray(albaranOInspecciones) ? {} : albaranOInspecciones) || {};
    var p = Promise.resolve();
    var iterateComprobacion = function(comprobaciones) {
        for (let x = 0; x < comprobaciones.length; x++) {
            var comprobacion = comprobaciones[x];
            if (comprobacion.defectosSeleccionados && comprobacion.defectosSeleccionados.length) {
                for (var z = 0; z < comprobacion.defectosSeleccionados.length; z++) {
                    var defecto = comprobacion.defectosSeleccionados[z];
                    if (defecto.documentos && defecto.documentos.length) {
                        for (var y = 0; y < defecto.documentos.length; y++) {
                            p = (function(defecto, y) {
                                return p.then(function() {
                                    return new Promise(function(resolve, reject) {
                                        console.log('Subiendo documento (defecto): ', defecto.documentos[y].nombre + '.' + defecto.documentos[y].extension);
                                        Documento.uploadRemote(defecto.documentos[y])
                                            .then(function(res) {
                                                //Sustituir campos
                                                if (res) {
                                                    defecto.documentos[y] = Object.assign(defecto.documentos[y], res);
                                                }
                                                resolve();
                                            })
                                            .catch(function(e) {
                                                reject(e);
                                            });
                                    });
                                })
                            })(defecto, y);
                        }
                    }
                }
            }
            if (comprobacion.documentos && comprobacion.documentos.length) {
                for (let i = 0; i < comprobacion.documentos.length; i++) {
                    p = (function(comprobacion, i) {
                        return p.then(function() {
                            return new Promise(function(resolve, reject) {
                                console.log('Subiendo documento (comprobación): ', comprobacion.documentos[i].nombre + '.' + comprobacion.documentos[i].extension);
                                Documento.uploadRemote(comprobacion.documentos[i])
                                    .then(function(res) {
                                        if (res) {
                                            comprobacion.documentos[i] = Object.assign(comprobacion.documentos[i], res);
                                        }
                                        resolve();
                                    })
                                    .catch(function(e) {
                                        reject(e);
                                    });
                            });
                        })
                    })(comprobacion, i);
                }
            }
            if (comprobacion.hijas && comprobacion.hijas.length) {
                iterateComprobacion(comprobacion.hijas);
            }
        }
    }

    for (var i = 0; i < inspecciones.length; i++) {
        iterateComprobacion(inspecciones[i].comprobaciones);
        if (inspecciones[i].documentos) {
            for (var j = 0; j < inspecciones[i].documentos.length; j++) {
                p = (function(i, j) {
                    return p.then(function() {
                        return new Promise(function(resolve, reject) {
                            Documento.uploadRemote(inspecciones[i].documentos[j])
                                .then(function(res) {
                                    //Sustituir campos
                                    if (res) {
                                        inspecciones[i].documentos[j] = Object.assign(inspecciones[i].documentos[j], res);
                                    }
                                    resolve();
                                })
                                .catch(function(e) {
                                    reject(e);
                                });
                        });
                    })
                })(i, j);
            }
        }
        if (inspecciones[i].tabs) {
            for (var k = 0; k < inspecciones[i].tabs.length; k++) {
                var tab = inspecciones[i].tabs[k];
                if (tab.items) {
                    for (var l = 0; l < tab.items.length; l++) {
                        var elemento = tab.items[l];
                        if (elemento) {
                            iterateComprobacion(elemento.comprobaciones);
                            if(elemento.documentos) {
                                for (var m = 0; m < elemento.documentos.length; m++) {
                                    p = (function(elemento, m) {
                                        return p.then(function() {
                                            return new Promise(function(resolve, reject) {
                                                Documento.uploadRemote(elemento.documentos[m])
                                                    .then(function(res) {
                                                        //Sustituir campos
                                                        if (res) {
                                                            elemento.documentos[m] = Object.assign(elemento.documentos[m], res);
                                                        }
                                                        resolve();
                                                    })
                                                    .catch(function(e) {
                                                        reject(e);
                                                    });
                                            });
                                        })
                                    })(elemento, m);
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    if (albaran.albaranFirmado) {
        p = (function() {
            return p.then(function() {
                return new Promise(function(resolve, reject) {
                    Documento.uploadRemote(albaran.albaranFirmado)
                        .then(function(res) {
                            //Sustituir campos
                            if (res) {
                                albaran.albaranFirmado = Object.assign(albaran.albaranFirmado, res);
                            }
                            resolve();
                        })
                        .catch(function(e) {
                            reject(e);
                        });
                });
            })
        })();
    }

    return p.then(function() {
        return Promise.resolve(albaranOInspecciones);
    });
};

DatabaseModel.download = function(url, name, data, method) {
    method = method || 'GET';
    var self = this;
    return new Promise((resolve, reject) => {
        self.request({
            url: url,
            method: method,
            crossDomain: true,
            xhrFields: { 'responseType': 'arraybuffer' },
            contentType: 'application/json',
            data: data,
            success: function(data) {
                FileUtils.openFile(data, name, resolve, reject);
            },
            error: function(error) {
                reject(error);
            }
        });
    });
};

DatabaseModel.comprobarExpedientes = function(expedientes) {
    const self = this;
    for (let i = 0; i < expedientes.length; i++) {
        const exp = expedientes[i];
        var errores = [];
        self.comprobacionesConDefectosNaranjas(exp.comprobaciones, errores);
        if (errores && errores.length) {
            return {idExp: exp.id, errores: errores};
        }
    }
};

DatabaseModel.comprobacionesConDefectosNaranjas = function(comprobaciones, errores) {
    const self = this;
    comprobaciones.forEach(comp => {

        if (comp.hijas && comp.hijas.length) {
            self.comprobacionesConDefectosNaranjas(comp.hijas, errores);
        } else {
            var err = self.comprobacionConDefectosNaranjas(comp);
            if (err) {
                errores.push(err);
            }
        }

    })

};

DatabaseModel.comprobacionConDefectosNaranjas = function(comprobacion) {
    if (comprobacion.resultado == "D" || comprobacion.resultado == "DL" || comprobacion.resultado == "DG" || comprobacion.resultado == "DMG") {
        if (!comprobacion.defectosSeleccionados || !comprobacion.defectosSeleccionados.length) {
            return "Se debe seleccionar un defecto para la comprobación " + comprobacion.codigo + ' - ' + comprobacion.nombre;
        }
    }
};

export default DatabaseModel;
